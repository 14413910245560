
































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import QueryMethods from '@/components/atoms/QueryMethods.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import { GetStudentsResponseType } from '@/models/api/students'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    SelectBase,
    RadioGroup,
    ButtonBase,
    TableBase,
  },
})
export default class BranchLearningSituation extends Mixins(QueryMethods, LocalMoment, LoadClassOptionsApi) {
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  /** パンくず */
  private breadcrumbs = [{ text: '全体管理', active: true }]

  /** 教室ID */
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  /** 項目検索 学年データ */
  private gradeOptionData: { text: string; value: number }[] = []
  /** 項目検索 学年選択値 */
  private gradeSelectedValue: number | null = null

  /** 項目検索 教科データ */
  private subjectOptionData: { text: string; value: number }[] = []
  /** 項目検索 教科選択値 */
  private subjectSelectedValue: number | null = null

  /** 集計検索 対象データ */
  private targetRadioData = [
    { value: 0, label: '学年' },
    { value: 1, label: 'クラス・コマ' },
    { value: 2, label: 'グループ' },
  ]
  /** 集計検索 対象選択値 */
  private targetRadioCheckedValue = 0

  /** 集計検索 クラス・コマデータ */
  private classOptionData: { text: string; value: string }[] = []
  /** 集計検索 属性データ */
  private attributeOptionData: { text: string; value: number }[] = []
  /** 集計検索 対象のデータ（学年、クラス・コマ、属性） */
  private targetOptionData: { text: string; value: number | string }[] = []
  /** 集計検索 対象のデータの選択値 */
  private targetSelectedValue: number | string = 0
  /** 集計検索 対象のデータのプレースホルダー */
  private targetPlaceholder = '全学年'

  /** 対象人数 */
  private totalCount = 0
  /** 実施人数 */
  private completedCount = 0

  /** 項目 テーブルヘッダー */
  private tableBaseFieldsCurriculum: object[] = [
    { key: 'id', label: '' },
    { key: 'gradeName', label: '学年' },
    { key: 'subjectName', label: '教科' },
    { key: 'classModeName', label: 'モード' },
    { key: 'curriculumSUnitName', label: '項目名', thStyle: 'width: 600px' },
  ]
  /** 項目 */
  private tableBaseItemsCurriculum: {
    id: { value: number; label: string }
    gradeName: string
    subjectName: string
    classModeName: string
    curriculumSUnitName: string
  }[] = []
  /** 項目 ラジオボタン */
  private tableBaseRadiosCurriculum = ['id']
  /** 項目 選択値 */
  private unitCheckedValue: number | null = null

  /** 集計結果 */
  private tableBaseItemsHistory: {
    userId: string
    userNickname: string
    result: string
    date: string
    action: { onclick: Function; name: string }[]
  }[] = []
  /** 集計結果 テーブルヘッダー */
  private tableBaseFieldsHistory: object[] = [
    { key: 'userNickname', label: '氏名' },
    { key: 'result', label: '最新結果' },
    { key: 'date', label: '最終実施日' },
    { key: 'action', label: '' },
  ]
  /** 集計結果 詳細ボタン */
  private tableBaseButtons = ['action']

  /**
   * 項目別検索ボタンの色（ボタン操作制御）
   */
  private get colorTypeItem(): string {
    return !!this.gradeSelectedValue && !!this.subjectSelectedValue ? 'blue' : 'pointer-events-none'
  }

  /**
   * 初回情報読み込み
   */
  private mounted(): void {
    this.loadInitialize()
  }

  /**
   * 初回情報読み込み
   */
  private async loadInitialize(): Promise<void> {
    this.setParamsFromQuery()
    await this.loadSelectData()

    // 項目検索の学年、教科が選択済みの場合、カリキュラム小項目を読み込む
    if (this.gradeSelectedValue && this.subjectSelectedValue) await this.loadCurriculums()
    // 項目検索の学年、教科、集計の集計対象とそのデータが選択済みの場合、集計結果を読み込む
    if (this.gradeSelectedValue && this.subjectSelectedValue && this.unitCheckedValue) {
      this.changeTargetOptionData(this.targetRadioCheckedValue, this.getParamsObject()['targetSelect'])
      await this.loadHistories()
    }
  }

  /**
   * 検索項目の情報をそれぞれ取得
   */
  private async loadSelectData(): Promise<void> {
    await this.loadGrades()
    await this.loadSubjects()
    await this.loadClasses()
    await this.loadAttributes()
  }

  /**
   * 学年情報読み込み
   */
  private async loadGrades(): Promise<void> {
    Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptionData = res.data.map((grade: { id: number; name: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })

      this.targetOptionData = this.gradeOptionData
    })
  }

  /**
   * 教科情報読み込み
   */
  private async loadSubjects(): Promise<void> {
    Vue.prototype.$http.httpWithToken.get(`/subjects`).then((res: any) => {
      this.subjectOptionData = res.data.map((subject: { id: number; name: string }) => {
        return {
          text: subject.name,
          value: subject.id,
        }
      })
    })
  }

  /**
   * カリキュラム小項目読み込み
   */
  private async loadCurriculums(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get(`/curriculum_s_units`, {
        params: {
          gradeId: this.gradeSelectedValue,
          subjectId: this.subjectSelectedValue,
        },
      })
      .then((res: any) => {
        this.tableBaseItemsCurriculum = res.data.curriculumSUnits.map((curriculumSUnit: any) => {
          return {
            id: { value: curriculumSUnit.id, label: '' },
            gradeName: curriculumSUnit.gradeName,
            subjectName: curriculumSUnit.subjectName,
            classModeName: '通常',
            curriculumSUnitName: curriculumSUnit.curriculumSUnitName,
          }
        })
      })
  }

  /**
   * 集計結果読み込み
   */
  private async loadHistories(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get('/history/branchLearningSituations/', {
        params: {
          branchId: this.branchId,
          curriculumSUnitId: this.unitCheckedValue,
          targetRadioCheckedValue: this.targetRadioCheckedValue,
          targetSelectedValue: this.targetSelectedValue,
        },
      })
      .then(({ data }: { data: any }) => {
        this.tableBaseItemsHistory = data.map((result: any) => {
          return {
            userId: result.userId,
            userNickname: result.userNickname,
            result:
              result.correctCount != null && result.questionCount != null
                ? `${result.correctCount}/${result.questionCount}`
                : '未実施',
            date: result.completedAt != null ? moment(result.completedAt).format('YYYY/MM/DD') : '-',
            action: [{ onclick: this.transition, variable: result.userId, name: '詳細' }],
          }
        })

        this.totalCount = data.length
        this.completedCount = data.filter((result: any) => result.completedAt != null).length
      })
  }

  /**
   * クラス・コマ情報読み込み
   */
  private async loadClasses(): Promise<void> {
    this.classOptionData = await this.loadClassOptions(this.branchId)
  }

  /**
   * ユーザ属性読み込み
   */
  private async loadAttributes(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get(`/user_attributes`, { params: { branchId: this.branchId } })
      .then((res: any) => {
        // 属性セレクトボックスの情報設定
        this.attributeOptionData = res.data.userAttributes.map((userAttributes: { id: number; title: string }) => {
          return {
            text: userAttributes.title,
            value: userAttributes.id,
          }
        })
      })
  }

  /**
   * 項目検索
   */
  private searchItems(): void {
    this.setQueryFromParams()
    this.loadCurriculums()
  }

  /**
   * 集計
   */
  private searchResult(): void {
    this.setQueryFromParams()
    this.loadHistories()
  }

  /**
   * クエリから検索条件を復元
   */
  private setParamsFromQuery(): void {
    const params = this.getParamsObject()

    // 学年
    if (params['grade']) {
      this.gradeSelectedValue = params['grade']
    }
    // 教科
    if (params['subject']) {
      this.subjectSelectedValue = params['subject']
    }
    // 項目
    if (params['unit']) {
      this.unitCheckedValue = Number(params['unit'])
    }
    // 学習対象（学年、クラス・コマ、属性）
    if (params['targetRadio']) {
      this.targetRadioCheckedValue = Number(params['targetRadio'])
    }
    // 学習対象
    if (params['targetSelect']) {
      this.targetSelectedValue = params['targetSelect']
    }
  }

  /**
   * クエリに検索条件を設定
   */
  private setQueryFromParams(): void {
    const params = {}
    // 学年
    if (this.gradeSelectedValue != null) {
      params['grade'] = this.gradeSelectedValue
    }
    // 教科
    if (this.subjectSelectedValue != null) {
      params['subject'] = this.subjectSelectedValue
    }
    // 項目
    if (this.unitCheckedValue != null) {
      params['unit'] = this.unitCheckedValue
    }
    // 学習対象（学年、クラス・コマ、グループ）
    if (this.targetRadioCheckedValue != null) {
      params['targetRadio'] = this.targetRadioCheckedValue
    }
    // 学習対象
    if (this.targetSelectedValue != null) {
      params['targetSelect'] = this.targetSelectedValue
    }

    this.setUrlAsParams(params)
  }

  /**
   * 項目を選択した場合
   *
   * @param {number} value 選択値
   */
  private onCurriculumChange(value: number): void {
    this.unitCheckedValue = value
  }

  /**
   * 集計対象を選択した場合
   *
   * @param {number} value 選択値
   */
  private onTargetRadioChange(value: number): void {
    this.targetRadioCheckedValue = value
  }

  /**
   * 集計結果の詳細ボタン押下時
   *
   * @param {number} userId ユーザID
   * @return {Promise}
   */
  private async transition(userId: number): Promise<void> {
    // 該当生徒のデータをCookieにセットする
    await this.setCookieStudentInfo(userId)

    window.location.href = `/teacher/history/normal/study-unit/${this.unitCheckedValue}?prevPage=${
      this.$route.name
    }&${window.location.search.slice(1)}`
  }

  /**
   * 生徒情報を取得してCookieにセットする
   */
  private async setCookieStudentInfo(userId: number) {
    const { data }: { data: GetStudentsResponseType } = await Vue.prototype.$http.httpWithToken.get(`/students`, {
      params: {
        userId: userId,
        branchId: this.branchId,
      },
    })
    // 生徒情報が正常に取得できない場合は履歴トップへ遷移
    if (data.count !== 1) {
      this.$router.push({ name: 'History' })
      return
    }
    const student = data.students[0]
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie.student = {
      userId: student.id,
      nickname: student.nickname,
      iconUrl: student.iconUrl,
      studentId: student.studentId,
      studentCode: student.studentCode,
      schoolName: student.schoolName,
      gradeName: student.gradeName,
    }
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * 集計対象が変更された場合のセレクトボックスの値の処理
   *
   * @param {number} value 集計対象のラジオボタンの選択値
   * @param {number | string | null} selectedValue 集計対象のセレクトボックスの選択値
   * @return {Promise}
   */
  private changeTargetOptionData(value: number, selectedValue: number | string): void {
    switch (value) {
      case 0:
        this.targetOptionData = this.gradeOptionData
        this.targetSelectedValue = Number(selectedValue)
        this.targetPlaceholder = '全学年'
        break

      case 1:
        this.targetOptionData = this.classOptionData
        this.targetSelectedValue = selectedValue
        this.targetPlaceholder = '全クラス・コマ'
        break

      case 2:
        this.targetOptionData = this.attributeOptionData
        this.targetSelectedValue = Number(selectedValue)
        this.targetPlaceholder = '全グループ'
        break
    }
  }

  @Watch('targetRadioCheckedValue')
  ontargetRadioCheckedValueChanged(newValue: number): void {
    this.changeTargetOptionData(newValue, 0)
  }
}
